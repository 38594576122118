import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { IPhone } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>
  Trailguide lets you use multiple different maps as a base map, including the
  map you find on mtbmap.no. This is how you do it:
    </p>
    <br />
    <div className="flex flex-wrap justify-left">
  <IPhone alt="Click the map selector" img="/howto/mtbmap-1.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="1) Click the map selector" mdxType="IPhone" />
  <IPhone alt="Choose MTBmap" img="/howto/mtbmap-2.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="2) Choose MTBmap" mdxType="IPhone" />
  <IPhone alt="Voila!" img="/howto/mtbmap-3.png" className="sm:w-1/4 mt-12 mb-4 mx-4" text="3) Voila!" mdxType="IPhone" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      